.fixed_header {
    table-layout: fixed;
    width: 100%;
}

.fixed_header thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}


.fixed_header th,td {
   text-align: center;
}

td:first-child{
    text-align: left;
}

.fixed_header tbody {
    display: block;
    overflow-y: auto;
    width: 100%;
    
}

.fixed_header tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}