*{
  overflow-x: hidden;
}
:root {
    --primaryColor: #59BFEC;
    --secondaryColor: #fc7b04;
    --lightSecondaryColor:#CED4E6;
    --whiteColor:#ffffff;
    --greyColor:#727C95;
    --blackColor:#000000;
    --greyColor2:#222631;
    --greenColor:#00BC4B;
    --strokeColor:#EEEEEE;
    --greyColor3:#f1f2fa;
}  
 
 
.logout-icon {
  transition: color 0.3s ease, transform 0.3s ease;
}
 
.logout-icon:hover {
  color: #4f8ae8; /* Change the color on hover (you can customize the color) */
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
}
 
 
 
body,html{
    font-family: "Cairo", sans-serif;
    font-optical-sizing: auto;
    font-size: 14px;
    background-color: #f4f5fa;
 
}
 
h1,h2,h3,h4,h5,h6{margin: 0px; padding:0;}
p{margin: 0px; padding:0;}
 
.pageloaded  main aside ul li .material-symbols-outlined{font-size:24px;}
 
main{display: flex; width: 100%;}
main aside{position: fixed; padding: 10px 0; gap:20px; width: 100%; max-width: 90px; top:0; bottom:0; left:0; background-color: var(--whiteColor); box-shadow: 0px 4px 25px 0px #7062E31A; display: flex; flex-direction: column;}
main aside ul{margin:0; padding: 0; display: flex; flex-direction: column; gap:20px; width: 100%; align-items: center;}
main aside ul li{margin:0; padding: 0; display: flex; width: 100%; border-radius: 10px; width: 48px; height: 48px; justify-content: center; align-items: center;}
main aside ul li:hover{background-color:#F4F5FA;}
main aside ul li .material-symbols-outlined{font-size: 0px;}
main aside ul li.selected{background-color:#F4F5FA;}
main aside ul li a.active{background-color:#F4F5FA;}
main aside ul li a.active span{color:var(--primaryColor);}
main aside ul li a{color: var(--greyColor); width:100%; border-radius: 10px;  height: 100%; display: flex; align-items: center; justify-content: center; text-decoration: none;   }
main aside ul li a:hover span{color: var(--primaryColor);}
main aside ul li a.active span{color: var(--primaryColor); background-color:#F4F5FA;}
main aside .logo{display: flex; justify-content: center;}
/* .mainContentWrapper{margin-left: 90px; display: flex; flex-direction: column; flex:1} */
.mainContentWrapper{ display: flex; flex-direction: column; flex:1}
.mainWrapper{padding: 20px;}
main header .headerContainer .headerActions{display: flex; align-items: center; gap:20px;}
main header{display: flex; width: 100%; background-color: var(--whiteColor); box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);}
main header .headerContainer{background-color: var(--whiteColor);    padding:10px 20px; display: flex; width: 100%; justify-content: space-between; align-items: center;}
main header .headerContainer .headerActions .notification span{font-variation-settings: 'FILL' 1; display: flex; font-size: 20px; align-items: center; justify-content: center; background-color:#fde5ad; width: 29px; height: 29px; border-radius: 100%;}
header .headerContainer .profileBlock{display: flex; align-items: center;  margin-left:0px; margin-right:0px; position: relative;}
header .headerContainer .profileBlock .accountDD{display: flex; align-items: center;}
header .headerContainer .profileBlock .accountDD  div > p{font-size:var(--fontsize12); color:var(--blackColor); line-height: 10px;}
header .headerContainer .profileBlock .accountDD i + div{align-items: flex-start; text-align: left;}
header .headerContainer .profileBlock .accountDD i{ display: flex; align-items: center; margin-right: 10px; color: var(--whitecolor); position: relative; justify-content: center; background-color: var(--primaryColor); background-color: transparent; width: 29px; height:29px; border-radius:var(--radius5);}
header .headerContainer .profileBlock .accountDD i img{max-width: 100%; width:var(--fullwidth); height:var(--fullheight); border-radius:var(--radius100);}
header .headerContainer .profileBlock .accountDD span{font-size:var(--fontsize12); color: var(--headerThemeTextColorChange);}
header .headerContainer .profileBlock .accountDD h6{font-size:var(--fontsize15); color: var(--headerThemeTextColorChange); font-family: var(--fontfamilysemibold);}
header .headerContainer .profileBlock a.open + .dropdown-menu{display: flex; flex-direction: column;}
header .headerContainer .profileBlock a.open + .dropdown-menu .roleas{padding: 0.25rem 1.5rem}
header .headerContainer .profileBlock .dropdown-menu .dropdown-item{padding: 0.25rem 1.5rem; font-size:var(--fontsize14); color: var(--headerThemeTextColorChange); font-family: var(--fontfamilysemibold);}
header .headerContainer .profileBlock .dropdown-menu .dropdown-item:hover{background-color: var(--greyColor3); font-family: var(--fontfamilysemibold);}
header .headerContainer .profileBlock .profilePic{display: flex; position: relative; border: 1px solid var(--lightBlue); align-items: center; justify-content: center; margin: 0 auto; width: 80px; height: 80px; border-radius: var(--radius100)}
header .headerContainer .profileBlock .profilePic img{border-radius: var(--radius100); width: var(--fullwidth); height: var(--fullwidth);}
header .headerContainer .profileBlock .profilePic a{position: absolute; display: flex; align-items: center;  justify-content: center; width: 20px; color: var(--blackcolor); background-color: var(--whitecolor); box-shadow: 0 0 5px var(--lightBlue); height: 20px; border-radius: var(--radius100); right: -4px; bottom:5px }
header .headerContainer .profileBlock .userName{display: flex;  margin: 15px 0;  justify-content: center; font-size:var(--fontsize20); color: var(--blackcolor); font-family: var(--fontfamilysemibold); width: var(--fullwidth); text-align: center; align-items: center;}
header .headerContainer .profileBlock .userName a{margin-left: 10px; display: flex; align-items: center;  justify-content: center;  color: var(--blackcolor);}
header .headerContainer .profileBlock .dropdown-menu {min-width: 300px; border-radius: 10px; padding:20px 10px; left:auto; right:0; }
header .headerContainer .profileBlock .botttomContent{border-top: 1px solid var(--greyColor4); border-bottom: 1px solid var(--greyColor4); padding: 10px 20px 0; margin-top:0px; flex-direction: column; display: flex; width: var(--fullwidth);}
header .headerContainer .profileBlock .botttomContent .block{display: flex; flex-direction: column; width: var(--fullwidth); margin-bottom: 15px;}
header .headerContainer .profileBlock .botttomContent .block label{display: flex; width: var(--fullwidth); font-size:var(--fontsize12); color: var(--greyColor5); font-family: var(--defaultfont);}
header .headerContainer .profileBlock .botttomContent .block span{display: flex; width: var(--fullwidth); font-size:var(--fontsize14); color: var(--blackcolor); font-family: var(--defaultfont);}
header .headerContainer .logoutLink{display: flex; align-items: center; margin-top:0px; justify-content: center;}
header .headerContainer .logoutLink i{margin-left: 5px; color: var(--blackcolor);}
header .headerContainer .logoutLink  a{color: var(--blackcolor); font-size:var(--fontsize14); padding: 0;}
header .headerContainer .profileBlock .dropdown-menu .logoutLink{margin-top: 10px;}
 
.overviewWrapper .cardInfoWrapper ol li .topSection .icons {
  display: flex;
  width: 6%;
  gap: 16px;
  background-color: #9fd01a;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  color: var(--whiteColor);
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  width: 36px;
}
.overviewWrapper .cardInfoWrapper ol li .topSection .icons2{
  background-color: #3ac394;
  display: flex;
  width: 6%;
  gap: 10px;
  /* background-color: #9fd01a; */
  align-items: center;
  border-radius: 8px;
  color: #fff;
  color: var(--whiteColor);
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  width: 36px;
}
 
.overviewWrapper .cardInfoWrapper ol li .topSection .icons3{
  background-color: #6e5fd4;
  display: flex;
  width: 6%;
  gap: 10px;
  /* background-color: #9fd01a; */
  align-items: center;
  border-radius: 8px;
  color: #fff;
  color: var(--whiteColor);
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  width: 36px;
}
 
.overviewWrapper .cardInfoWrapper ol li .topSection .icons4{
  background-color: #ff9a3e;
  display: flex;
  width: 6%;
  gap: 10px;
  /* background-color: #9fd01a; */
  align-items: center;
  border-radius: 8px;
  color: #fff;
  color: var(--whiteColor);
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  width: 36px;
}
/* .................................. */
 
 
 
 
 
.overviewWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.overviewWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.overviewWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.overviewWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px;}
.overviewWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:10px;}
.overviewWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.overviewWrapper .cardInfoWrapper{display: flex;width: 100%;}
.overviewWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:10px; display:grid;  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr)); width: 100%;}
/* .overviewWrapper .cardInfoWrapper ol li{display: flex; flex-direction: column; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);} */
.overviewWrapper .cardInfoWrapper ol li {
    display: flex;
    flex-direction: row; /* Change to row to keep items on one line */
    align-items: center; /* Align items vertically in the center */
    border-radius: 10px;
    padding: 10px;
    background-color: var(--whiteColor);
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any overflowed content */
    text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
}
.overviewWrapper .cardInfoWrapper ol li .topSection{display: flex; width: 100%; gap:10px}
.overviewWrapper .cardInfoWrapper ol li a{display: flex; width: 100%; text-decoration: none; flex-direction: column;}
.overviewWrapper .cardInfoWrapper ol li:nth-child(1) .topSection i{background-color: #9FD01A;}
.overviewWrapper .cardInfoWrapper ol li:nth-child(2) .topSection i{background-color: #3AC394;}
.overviewWrapper .cardInfoWrapper ol li:nth-child(3) .topSection i{background-color: #6E5FD4;}
.overviewWrapper .cardInfoWrapper ol li:nth-child(4) .topSection i{background-color: #FF9A3E;}
.overviewWrapper .cardInfoWrapper ol li:nth-child(4) .actionInfo div:last-child span{ padding-left: 20px;}
.overviewWrapper .cardInfoWrapper ol li .topSection i{display: flex; width: 36px; align-items: center; justify-content: center; color: var(--whiteColor); height: 36px; flex-shrink: 0; border-radius: 8px;}
.overviewWrapper .cardInfoWrapper ol li .topSection div{display: flex; align-items: flex-start; width: 100%; flex-direction: column;}
.overviewWrapper .cardInfoWrapper ol li .topSection div p{line-height:15.24px; color:var(--greyColor2); font-size: 12px;}
.overviewWrapper .cardInfoWrapper ol li .topSection div h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.overviewWrapper .cardInfoWrapper ol li .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.overviewWrapper .cardInfoWrapper ol li .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.overviewWrapper .cardInfoWrapper ol li .progressInfo div{display: flex; align-items: center; gap:5px;}
.overviewWrapper .cardInfoWrapper ol li .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.overviewWrapper .cardInfoWrapper ol li .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.overviewWrapper .cardInfoWrapper ol li .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--greenColor); transition: width .6s ease;}
.overviewWrapper .cardInfoWrapper ol li .actionInfo{ border-top: 1px solid var(--strokeColor); gap:15px; font-size: 13px; margin-top: 10px; padding-top: 10px; display: flex; color:var(--greyColor); width: 100%; justify-content: space-between; align-items:flex-start;}
.overviewWrapper .cardInfoWrapper ol li .actionInfo div {display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;}
.overviewWrapper .cardInfoWrapper ol li .actionInfo div span{font-size: 18px; line-height: normal; color: var(--greyColor2); font-weight: 700;}
.overviewWrapper .cardInfoWrapper ol li .actionInfo div p{text-align: left;}
.chatWrapper .highcharts-container {overflow-x: scroll;}
.overviewWrapper .chartWrapper{display: flex; width: 100%; gap:20px}
.overviewWrapper .chartWrapper .chartLeft{display: flex; width: 100%; flex-direction: column; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.overviewWrapper .chartWrapper .chartRight{display: flex; width: 100%; flex-direction: column; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.overviewWrapper .chartWrapper .chartheading{display: flex; border-bottom: 1px solid var(--strokeColor); width: 100%; padding-bottom: 5px; margin-bottom: 20px; justify-content: space-between; align-items: center;}
.overviewWrapper .chartWrapper .chartheading h3{display: flex; font-size: 18px; font-weight: 700; }
.overviewWrapper .chartWrapper .chartheading div:nth-child(2){display: flex; gap:10px;}
.overviewWrapper .chartWrapper .chartheading span{background-color: #F1F2FA; padding:2px  10px; border-radius: 4px; color:var(--greyColor)}
.overviewWrapper .chartWrapper .chartheading span:hover{color:var(--greyColor2); cursor: pointer;}
.overviewWrapper .chartWrapper .chartheading span.active{color:var(--greyColor2);}
.overviewWrapper .chartWrapper .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.overviewWrapper .chartWrapper .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.overviewWrapper .chartWrapper .progressInfo div{display: flex; align-items: center; gap:5px;}
.overviewWrapper .chartWrapper .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.overviewWrapper .chartWrapper .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.overviewWrapper .chartWrapper .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--primaryColor); transition: width .6s ease;}
.overviewWrapper .chartWrapper .chartAlignmentWrapper{display: flex; justify-content: space-between; flex-direction: column; height: 100%;}
.overviewWrapper .chartWrapper .chartAlignmentWrapper  .progressInfo div{color:var(--blackColor); font-size: 18px;}
 
 
 
 
 
 
.revenueWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.revenueWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.revenueWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.revenueWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px;}
.revenueWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:10px;}
.revenueWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.revenueWrapper .cardInfoWrapper{display: flex;width: 100%;}
.revenueWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:20px; display:grid;  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); width: 100%;}
.revenueWrapper .cardInfoWrapper ol li{display: flex; flex-direction: column; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.revenueWrapper .cardInfoWrapper ol li .topSection{display: flex; width: 100%; gap:10px}
.revenueWrapper .cardInfoWrapper ol li:nth-child(1) .topSection i{background-color: #9FD01A;}
.revenueWrapper .cardInfoWrapper ol li:nth-child(2) .topSection i{background-color: #3AC394;}
.revenueWrapper .cardInfoWrapper ol li:nth-child(3) .topSection i{background-color: #6E5FD4;}
.revenueWrapper .cardInfoWrapper ol li:nth-child(4) .topSection i{background-color: #FF9A3E;}
.revenueWrapper .cardInfoWrapper ol li .topSection i{display: flex; width: 36px; align-items: center; justify-content: center; color: var(--whiteColor); height: 36px; flex-shrink: 0; border-radius: 8px;}
.revenueWrapper .cardInfoWrapper ol li .topSection div{display: flex; align-items: flex-start; width: 100%; flex-direction: column;}
.revenueWrapper .cardInfoWrapper ol li .topSection div p{line-height:15.24px; color:var(--greyColor2); font-size: 12px;}
.revenueWrapper .cardInfoWrapper ol li .topSection div h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.revenueWrapper .cardInfoWrapper ol li .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.revenueWrapper .cardInfoWrapper ol li .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.revenueWrapper .cardInfoWrapper ol li .progressInfo div{display: flex; align-items: center; gap:5px;}
.revenueWrapper .cardInfoWrapper ol li .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.revenueWrapper .cardInfoWrapper ol li .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.revenueWrapper .cardInfoWrapper ol li .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--greenColor); transition: width .6s ease;}
.revenueWrapper .cardInfoWrapper ol li .actionInfo{ border-top: 1px solid var(--strokeColor); font-size: 13px; margin-top: 10px; padding-top: 10px; display: flex; color:var(--greyColor); width: 100%; justify-content: space-between; align-items:flex-start;}
.revenueWrapper .cardInfoWrapper ol li .actionInfo div {display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;}
.revenueWrapper .cardInfoWrapper ol li .actionInfo div span{font-size: 18px; line-height: normal; color: var(--greyColor2); font-weight: 700;}
.revenueWrapper .chartWrapper{display: flex; width: 100%; gap:20px}
.revenueWrapper .chartWrapper .chartLeft{display: flex; width: 100%; flex-direction: column; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.revenueWrapper .chartWrapper .chartRight{display: flex; width: 100%; flex-direction: column; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.revenueWrapper .chartWrapper .chartheading{display: flex; border-bottom: 1px solid var(--strokeColor); width: 100%; padding-bottom: 5px; margin-bottom: 20px; justify-content: space-between; align-items: center;}
.revenueWrapper .chartWrapper .chartheading h3{display: flex; font-size: 18px; font-weight: 700; }
.revenueWrapper .chartWrapper .chartheading .block{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.revenueWrapper .chartWrapper .chartheading .block a{display: flex; align-items: center; gap:5px; color:var(--blackColor); text-decoration: none;;}
.revenueWrapper .chartWrapper .chartheading div:nth-child(2){display: flex; gap:10px;}
.revenueWrapper .chartWrapper .chartheading span{background-color: #F1F2FA; padding:2px  10px; border-radius: 4px; color:var(--greyColor)}
.revenueWrapper .chartWrapper .chartheading span:hover{color:var(--greyColor2); cursor: pointer;}
.revenueWrapper .chartWrapper .chartheading span.active{color:var(--greyColor2);}
.revenueWrapper .chartWrapper .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.revenueWrapper .chartWrapper .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.revenueWrapper .chartWrapper .progressInfo div{display: flex; align-items: center; gap:5px;}
.revenueWrapper .chartWrapper .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.revenueWrapper .chartWrapper .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.revenueWrapper .chartWrapper .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--primaryColor); transition: width .6s ease;}
.revenueWrapper .chartWrapper .chartAlignmentWrapper{display: flex; justify-content: space-between; flex-direction: column; height: 100%;}
.revenueWrapper .chartWrapper .chartAlignmentWrapper  .progressInfo div{color:var(--blackColor); font-size: 18px;}
.revenueWrapper .chartWrapper .totalRevenueWrapper{display: flex; align-items: flex-start; flex-direction: column; margin-bottom: 20px;}
.revenueWrapper .chartWrapper .totalRevenueWrapper h5{display: flex; font-size: 18px; font-weight: 700; }
.revenueWrapper .chartWrapper .totalRevenueWrapper p{display: flex; font-size: 14px; gap:10px;}
.revenueWrapper .chartWrapper .totalRevenueWrapper p span{color: var(--greenColor);}
.revenueWrapper .chartWrapper select{background-color:var(--greyColor3);border-radius: 5px; padding:8px 10px; border: 0px none;}
.revenueWrapper .chartWrapper select:focus{outline: 0px none;}
 
 
 
 
 
 
.enrollmentsWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.enrollmentsWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.enrollmentsWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.enrollmentsWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px;}
.enrollmentsWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:10px;}
.enrollmentsWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.enrollmentsWrapper .cardInfoWrapper{display: flex;width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:20px; display:grid;  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li{display: flex; gap:30px; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.enrollmentsWrapper .cardInfoWrapper ol li .cardLeft{display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap:10px; max-width:35%;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardLeft p{line-height:15.24px; color:var(--greyColor2); font-size: 14px;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardLeft h2 + p{line-height:15.24px; color:var(--greenColor); font-size: 14px; font-weight: 400;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardLeft h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.enrollmentsWrapper .cardInfoWrapper ol li .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.enrollmentsWrapper .cardInfoWrapper ol li .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.enrollmentsWrapper .cardInfoWrapper ol li .progressInfo div{display: flex; align-items: center; gap:5px;}
.enrollmentsWrapper .cardInfoWrapper ol li .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.enrollmentsWrapper .cardInfoWrapper ol li .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.enrollmentsWrapper .cardInfoWrapper ol li .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--greenColor); transition: width .6s ease;}
.enrollmentsWrapper .cardInfoWrapper ol li .chartInfo{display: flex; gap:20px; max-width:36%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .chartInfo p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.enrollmentsWrapper .cardInfoWrapper ol li .chartWrapper{display: flex; max-width:64%; width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight{display: flex; border-left: 1px solid #CED4E7; width: 100%; gap:20px; max-width: 65%; gap: 20px; align-items: center;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight .chartWrapper{display: flex; max-width:60%; width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight .chartInfo{display: flex; gap:20px; max-width:40%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.enrollmentsWrapper .cardInfoWrapper ol li .cardRight h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
 
.enrollmentsWrapper .chartWrapper{display: flex; width: 100%; gap:20px;}
.enrollmentsWrapper .left{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.enrollmentsWrapper .middle{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.enrollmentsWrapper .right{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.enrollmentsWrapper .chartWrapper .chartheading{display: flex; border-bottom: 1px solid var(--strokeColor); width: 100%; padding-bottom: 5px; margin-bottom: 20px; justify-content: space-between; align-items: center;}
.enrollmentsWrapper .chartWrapper .chartheading h3{display: flex; font-size: 18px; font-weight: 700; }
.enrollmentsWrapper .chartWrapper .chartheading div:nth-child(2){display: flex; gap:10px;}
.enrollmentsWrapper .chartWrapper .chartheading span{background-color: #F1F2FA; padding:2px  10px; border-radius: 4px; color:var(--greyColor)}
.enrollmentsWrapper .chartWrapper .chartheading span:hover{color:var(--greyColor2); cursor: pointer;}
.enrollmentsWrapper .chartWrapper .chartheading span.active{color:var(--greyColor2);}
.enrollmentsWrapper .chartWrapper select{display: flex; border:0px solid var(--greyColor3); background-color: var(--greyColor3); padding: 10px; border-radius: 5px;}
.enrollmentsWrapper .chartWrapper .left.singleChart{width: 100%; max-width: none;}
 
 
 
.programmesWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.programmesWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.programmesWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.programmesWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px; display: flex; align-items: center; gap:10px; gap:10px;}
.programmesWrapper .infoWrapper .pageTitle h1 i{color:var(--greyColor4); cursor: pointer;}
.programmesWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:10px;}
.programmesWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.programmesWrapper .cardInfoWrapper{display: flex;width: 100%;}
.programmesWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:20px; display:grid;  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); width: 100%;}
.programmesWrapper .cardInfoWrapper ol li{display: flex; gap:30px; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.programmesWrapper .cardInfoWrapper ol li .cardLeft{display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap:10px; max-width:100%;}
.programmesWrapper .cardInfoWrapper ol li .cardLeft p{line-height:15.24px; color:var(--greyColor2); font-size: 14px;}
.programmesWrapper .cardInfoWrapper ol li .cardLeft h2 + p{line-height:15.24px; color:var(--greenColor); font-size: 14px; font-weight: 400;}
.programmesWrapper .cardInfoWrapper ol li .cardLeft h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.programmesWrapper .cardInfoWrapper ol li .chartInfo{display: flex; gap:20px; max-width:35%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .chartInfo p{line-height:15.24px; color:var(--greyColor); font-size: 12px;}
.programmesWrapper .cardInfoWrapper ol li .chartWrapper{display: flex; max-width:70%; width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .cardRight{display: flex; border-left: 1px solid #CED4E7; width: 100%; gap:20px; max-width:75%; padding-left: 30px; gap: 20px; align-items: center;}
.programmesWrapper .cardInfoWrapper ol li .cardRight .chartWrapper{display: flex; max-width:80%; width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .cardRight .chartInfo{display: flex; gap:20px; max-width:20%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.programmesWrapper .cardInfoWrapper ol li .cardRight p{line-height:15.24px; color:var(--greyColor); font-size: 12px; text-align: left;}
.programmesWrapper .cardInfoWrapper ol li .cardRight h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
 
.programmesWrapper .tableWrapper{border-radius: 10px; text-align:left; padding: 20px; background-color: var(--whiteColor);}
.programmesWrapper .tableWrapper table{width:100%; border-collapse: collapse;}
.programmesWrapper .tableWrapper table thead td{padding: 10px; color:var(--greyColor5); font-weight: 700;}
.programmesWrapper .tableWrapper table td{padding: 10px; color:var(--greyColor2); border-top:1px solid #BDBDBD}
.programmesWrapper .tableWrapper table tr:nth-child(even) {background-color: #f2f2f2;}
.programmesWrapper .tableWrapper table tr .thumb{max-width: 100px;}
.programmesWrapper .tableWrapper .search{display: flex; justify-content:flex-end; margin-bottom: 15px;}
.programmesWrapper .tableWrapper .search input{display: flex; border:0px none; background-color:#f2f2f2; padding: 10px; border-radius: 5px;}
.programmesWrapper .tableWrapper .rc-pagination{justify-content: flex-end; margin-top: 20px; display: flex;}
.programmesWrapper .tableWrapper .EnrolmentsClosed{background-color: #EA575E; border-radius: 5px; text-align:center; padding: 2px 5px; color: #fff;}
.programmesWrapper .tableWrapper .EnrolmentsinProgess{background-color:var(--greenColor); border-radius: 5px; text-align:center; padding: 2px 5px; color: #fff;}
.programmesWrapper .tableWrapper table td .link{font-weight: 700; cursor: pointer;}
 
 
 
.employabilityWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.employabilityWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.employabilityWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.employabilityWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px;}
.employabilityWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:10px;}
.employabilityWrapper .infoWrapper .pageActions label{display: flex; margin:0; padding:0; color: var(--greyColor);}
.employabilityWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.employabilityWrapper .cardInfoWrapper{display: flex;width: 100%;}
.employabilityWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:20px; display:grid;  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li{display: flex; gap:30px; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.employabilityWrapper .cardInfoWrapper ol li .cardLeft{display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap:10px; max-width:25%;}
.employabilityWrapper .cardInfoWrapper ol li .cardLeft p{line-height:15.24px; color:var(--greyColor2); font-size: 14px;}
.employabilityWrapper .cardInfoWrapper ol li .cardLeft h2 + p{line-height:15.24px; color:var(--greenColor); font-size: 14px; font-weight: 400;}
.employabilityWrapper .cardInfoWrapper ol li .cardLeft h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.employabilityWrapper .cardInfoWrapper ol li .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.employabilityWrapper .cardInfoWrapper ol li .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.employabilityWrapper .cardInfoWrapper ol li .progressInfo div{display: flex; align-items: center; gap:5px;}
.employabilityWrapper .cardInfoWrapper ol li .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.employabilityWrapper .cardInfoWrapper ol li .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.employabilityWrapper .cardInfoWrapper ol li .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--greenColor); transition: width .6s ease;}
.employabilityWrapper .cardInfoWrapper ol li .chartInfo{display: flex; gap:20px; max-width:36%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .chartInfo p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.employabilityWrapper .cardInfoWrapper ol li .chartWrapper{display: flex; max-width:64%; width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight{display: flex; border-left: 1px solid #CED4E7; padding-left: 30px; width: 100%; gap:20px; max-width: 65%; gap: 20px; align-items: center;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight .chartWrapper{display: flex; max-width:80%; width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight .chartInfo{display: flex; gap:20px; max-width:20%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.employabilityWrapper .cardInfoWrapper ol li .cardRight h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
 
.employabilityWrapper .chartWrapper{display: flex; width: 100%; gap:20px;}
.employabilityWrapper .left{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.employabilityWrapper .middle{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.employabilityWrapper .right{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.employabilityWrapper .chartWrapper .chartheading{display: flex; border-bottom: 1px solid var(--strokeColor); width: 100%; padding-bottom: 5px; margin-bottom: 20px; justify-content: space-between; align-items: center;}
.employabilityWrapper .chartWrapper .chartheading h3{display: flex; font-size: 18px; font-weight: 700; }
.employabilityWrapper .chartWrapper .chartheading div:nth-child(2){display: flex; gap:10px;}
.employabilityWrapper .chartWrapper .chartheading span{background-color: #F1F2FA; padding:2px  10px; border-radius: 4px; color:var(--greyColor)}
.employabilityWrapper .chartWrapper .chartheading span:hover{color:var(--greyColor2); cursor: pointer;}
.employabilityWrapper .chartWrapper .chartheading span.active{color:var(--greyColor2);}
.employabilityWrapper .chartWrapper select{display: flex; border:0px solid var(--greyColor3); background-color: var(--greyColor3); padding: 10px; border-radius: 5px;}
.employabilityWrapper .chartWrapper .left.singleChart{width: 100%; max-width: none;}
 
 
 
 
.detailsWrapper{display: flex; width: 100%; flex-direction: column; gap:30px}
.detailsWrapper .infoWrapper{display: flex; width: 100%; justify-content: space-between; align-items: center;}
.detailsWrapper .infoWrapper .pageTitle{display: flex; flex-direction: column; justify-items: flex-start; align-items:flex-start;}
.detailsWrapper .infoWrapper .pageTitle h1{margin:0; padding:0; font-size: 20px; display: flex; align-items: center; gap:10px; gap:10px;}
.detailsWrapper .infoWrapper .pageTitle h1 i{color:var(--greyColor4); cursor: pointer;}
.detailsWrapper .infoWrapper .pageActions{display: flex; align-items: center; gap:20px;}
.detailsWrapper .infoWrapper .pageActions div{display: flex; align-items: center; gap:5px;}
.detailsWrapper .infoWrapper .pageActions label{display: flex; margin:0; padding:0; color: var(--greyColor);}
.detailsWrapper .infoWrapper .pageActions select{display: flex; border:0px none; background-color: var(--whiteColor); padding: 10px; border-radius: 5px;}
.detailsWrapper .cardInfoWrapper{display: flex;width: 100%;}
.detailsWrapper .cardInfoWrapper ol{margin:0; padding: 0; gap:20px; display:flex; width: 100%;}
.detailsWrapper .cardInfoWrapper ol li{display: flex; gap:30px;width: 100%; align-items: flex-start; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.detailsWrapper .cardInfoWrapper ol li:nth-child(1){max-width: 70%;}
.detailsWrapper .cardInfoWrapper ol li:nth-child(2){max-width: 30%;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft{display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap:10px; max-width:25%;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft p{line-height:15.24px; color:var(--greyColor2); font-size: 14px;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft h2 + p{line-height:15.24px; color:var(--greenColor); font-size: 14px; font-weight: 400;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.detailsWrapper .cardInfoWrapper ol li .progressWrapper{display: flex; min-height: 36px; flex-direction: column; margin-top: 10px; color:var(--greyColor); font-size: 14px; width: 100%; align-items: flex-start;}
.detailsWrapper .cardInfoWrapper ol li .progressInfo{display: flex; color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.detailsWrapper .cardInfoWrapper ol li .progressInfo div{display: flex; align-items: center; gap:5px;}
.detailsWrapper .cardInfoWrapper ol li .progressInfo div span{color:var(--blackColor); font-weight: 700;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft:nth-child(1) .progress-bar{background-color: #00BC4B;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft:nth-child(2) .progress-bar{background-color: #EAB600;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft:nth-child(3) .progress-bar{background-color: #DC0036;}
.detailsWrapper .cardInfoWrapper ol li .cardLeft:nth-child(4) .progress-bar{background-color: #00BC4B;}
.detailsWrapper .cardInfoWrapper ol li .progress{display: flex; margin-top: 5px; border-radius: 4px; height:5px; background-color:var(--lightSecondaryColor); color:var(--greyColor); font-size: 14px; width: 100%; justify-content: space-between; align-items: center;}
.detailsWrapper .cardInfoWrapper ol li .progress .progress-bar{ height: 100%; border-radius: 4px; background-color: var(--greenColor); transition: width .6s ease;}
.detailsWrapper .cardInfoWrapper ol li .chartInfo{display: flex; gap:20px; max-width:36%; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .chartInfo p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.detailsWrapper .cardInfoWrapper ol li .chartWrapper{display: flex; max-width:64%; width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .cardRight{display: flex; width: 100%;     height: 100%; gap:20px; gap: 20px; align-items: center;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartWrapper{display: flex; width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartInfo{display: flex; gap:20px; max-width: none; flex-direction: row; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block{display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;  width: 100%;}
.detailsWrapper .cardInfoWrapper ol li .cardRight p{line-height:15.24px; color:var(--greyColor); font-size: 14px;}
.detailsWrapper .cardInfoWrapper ol li .cardRight h2{color:var(--greyColor2); font-size: 18px; font-weight:700;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block:nth-child(1) h2{color: #00BC4B;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block:nth-child(2) h2{color: #CB9E00;}
.detailsWrapper .cardInfoWrapper ol li .cardRight .chartInfo .block:nth-child(3) h2{color: #59BFEC;}
 
.detailsWrapper .chartWrapper{display: flex; width: 100%; gap:20px;}
.detailsWrapper .left{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.detailsWrapper .middle{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.detailsWrapper .right{ width: 100%; max-width: 33%; border-radius: 10px; padding: 20px; background-color: var(--whiteColor);}
.detailsWrapper .chartWrapper .chartheading{display: flex; border-bottom: 1px solid var(--strokeColor); width: 100%; padding-bottom: 5px; margin-bottom: 20px; justify-content: space-between; align-items: center;}
.detailsWrapper .chartWrapper .chartheading h3{display: flex; font-size: 18px; font-weight: 700; }
.detailsWrapper .chartWrapper .chartheading div:nth-child(2){display: flex; gap:10px;}
.detailsWrapper .chartWrapper .chartheading span{background-color: #F1F2FA; padding:2px  10px; border-radius: 4px; color:var(--greyColor)}
.detailsWrapper .chartWrapper .chartheading span:hover{color:var(--greyColor2); cursor: pointer;}
.detailsWrapper .chartWrapper .chartheading span.active{color:var(--greyColor2);}
.detailsWrapper .chartWrapper select{display: flex; border:0px solid var(--greyColor3); background-color: var(--greyColor3); padding: 10px; border-radius: 5px;}
.detailsWrapper .chartWrapper .left.singleChart{width: 100%; max-width: none;}
 
aside {
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Enable scrolling */
    position: relative; /* Make sure the down arrow stays within the sidebar */
    background-color: #f4f4f9; /* Optional background color */
  }
 
  nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
 
  li {
    padding: 10px 15px; /* Optional spacing for list items */
  }
 
  .logo {
    padding: 20px;
    text-align: center;
  }
 
  .scroll-down-btn {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-10%);
    cursor: pointer;
    padding: 10px;
    background-color: #e0e0e0; /* Optional background */
    border-radius: 10%;
    box-shadow: #f4f4f9;
    /* 0px 2px 8px rgba(0, 0, 0, 0.2); */
    transition: background-color 0.3s;
  }
 
  .scroll-down-btn:hover {
    background-color: #eee8e8; /* Darken the button on hover */
  }
 
  .ml-\[95px\] {
    margin-left: 55px;
}
 
.academicchart{
  display: flex;
}
 
.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.grid-container-Community{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.tableacademic{
  width: 50%;
}
.secondchartacademic{
  display: flex;
}
.tableemployabilityWrapper{
  display: flex;
}
.eventsTable{
  display: flex;
}
/* ------------------------------------------------------- */
 
@media (max-width: 768px) {
  .detailsWrapper {
    flex-direction: column;
  }
 
  .detailsWrapper .chartWrapper {
    flex-direction: column;
  }
 
  .detailsWrapper .chartWrapper .left,
  .detailsWrapper .chartWrapper .middle,
  .detailsWrapper .chartWrapper .right {
    max-width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
 
  }
 
  .detailsWrapper .chartWrapper .chartheading {
    flex-direction: column;
  }
 
  .detailsWrapper .chartWrapper .chartheading h3 {
    margin-bottom: 10px;
  }
 
  .detailsWrapper .chartWrapper .chartheading div:nth-child(2) {
    margin-top: 10px;
  }
 
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-container-Community{
    grid-template-columns: repeat(3, 1fr);
  }
  .tableacademic{
    width: 120%;
  }
  .secondchartacademic{
    display: block;
  }
  .tableemployabilityWrapper{
    display: block;
  }
  .eventsTable{
    display: block;
  }
 
  .font-bold {
    font-weight: 300;
}
h1 h2 h3 {
  font-weight: 200;
 
}
.tableWrapper{
  padding-left: 59px;
}
.tableprogram{
  /* margin-right: 49px; */
  padding-right: 66px;
}
}
 
@media (max-width: 480px) {
  .detailsWrapper {
    padding: 10px;
  }
 
  .detailsWrapper .chartWrapper {
    padding: 10px;
  }
 
  .detailsWrapper .chartWrapper .left,
  .detailsWrapper .chartWrapper .middle,
  .detailsWrapper .chartWrapper .right {
    margin-bottom: 10px;
  }
 
  .detailsWrapper .chartWrapper .chartheading {
    padding: 10px;
  }
 
  .detailsWrapper .chartWrapper .chartheading h3 {
    font-size: 16px;
  }
}
 
.highcharts-credits{
  display: none;
}
