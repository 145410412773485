@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login_page_img {
  background-image: url('https://d1.awsstatic.com/AdobeStock_536789107.bdb96e5b400deb045ef5ba238ca35e3b1e8fc14b.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Optional: centers the image */
  background-attachment: fixed; /* Optional: keeps the image fixed when scrolling */
}


.dashboard_event .cardLeft{
  width: 150px !important;

}

.chartWrapper{
  width: 100%;
}

